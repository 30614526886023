import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import styled, {keyframes} from "styled-components"
import get from "lodash/get"
import Layout from "../components/layout"
import Gallery from "react-grid-gallery"
import Helmet from "react-helmet"
import { useMedia } from "react-use"
import { IoArrowBack } from "@react-icons/all-files/io5/IoArrowBack"
import { IoStar } from "@react-icons/all-files/io5/IoStar"
import { IoStarOutline } from "@react-icons/all-files/io5/IoStarOutline"
import { useLocalStorage } from "react-use"


const ModelPageWrapper = styled.div`
    overflow: hidden;
    max-width: 80em;
    margin: 5em auto 0 auto;
    @media (min-width: 1000px) {
        margin: 6em auto 8em auto;
    }
`

const ModelInfoWrapper = styled.div`
    margin: 1em;
    display: flex;
    flex-direction: column;
    @media (min-width: 1000px) {
        flex-direction: row;
    }
`

const ModelName = styled.div`
    font-size: 13vw;
    font-family: Raleway, sans-serif;
    font-weight: 100;
    flex: 1;
    line-height: 0.9em;
    @media (min-width: 1000px) {
        font-size: 6em;
    }
`

const ModelInfo = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2em;
    @media (min-width: 1000px) {
        margin-top: 0;
    }
`

const ModelInfoRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 1em;
  margin-bottom: 1em;
  &:last-child {
    border-bottom: none;
  }
  @media (min-width: 1000px) {
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: 2em;
    margin-right: 0;
    margin-bottom: 0;
  }
`

const ModelInfoLabel = styled.div`
  color: #a0a0a0;
`

const ModelInfoValue = styled.div`
  text-transform: uppercase;
  font-family: Raleway, sans-serif;
  font-weight: 200;
  font-size: 1em;
  @media (min-width: 1000px) {
    font-size: 2em;
  }
`

const buttonFlash = keyframes`
  0% {
    background: #fff;
  }
  100% {
    background: transparent;
  }
`

const Button = styled.a`
  display: flex;
  border: 1px solid #fb578a;
  color: #fb578a;
  padding: 1em;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 400;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  &:hover {
    background: transparent;
    animation: ${buttonFlash} 250ms ease-out;
    border-color: #fff;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
  }
`

const GatsbyLinkButton = styled(Link)`
  display: flex;
  border: 1px solid #fb578a;
  color: #fb578a;
  padding: 1em;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 400;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  &:hover {
    background: transparent;
    animation: ${buttonFlash} 250ms ease-out;
    border-color: #fff;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
  }
`

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  @media (min-width: 480px) {
    flex-direction: row;
  }
  & > * {
      margin: 0.5em;
      & > *:first-child {
          margin-right: 0.5em;
      }
  }
`

const FavoriteButton = styled(Button)`

`

const ModelPage = ({ data }) => {
  const model = data.contentfulModel
  const id = get(model, "id")
  const name = get(model, "name")
  const images = get(model, "images")
  const isWide = useMedia('(min-width: 1000px)')
  const galleryImages = images && images.map((image) => {
      return {
        src: image.fixed.src,
        thumbnail: image.fixed.src,
        thumbnailWidth: image.fixed.width,
        thumbnailHeight: image.fixed.height,
        isSelected: false,
        caption: name
      }
  }) || []

  const [favorites, setFavorites] = useLocalStorage("favorites", [])

  const toggleFavorite = (id) => {
    setFavorites(
      (favorites.indexOf(id) !== -1)
      ? favorites.filter(f => f !== id) // exists, remove
      : [...favorites, id]) // doesn't exist, add
  }
  const isFavorite = (id) => {
    return favorites.indexOf(id) !== -1
  }


  return (
    <>
        <Helmet>
            <title>{name}</title>
        </Helmet>
        <Layout>
            <ModelPageWrapper>
                <ButtonsRow>
                    <GatsbyLinkButton to="/mallit"><IoArrowBack />Takaisin</GatsbyLinkButton>
                    <FavoriteButton onClick={(e) => {e.stopPropagation(); e.preventDefault(); toggleFavorite(id)}}>
                    {!isFavorite(id)
                    ? <><IoStarOutline /> Lisää suosikkeihin</>
                    : <><IoStar /> Poista suosikeista</>}
                    </FavoriteButton>
                </ButtonsRow>
                <ModelInfoWrapper>
                    <ModelName>{name}</ModelName>
                    <ModelInfo>
                        {model.height && <ModelInfoRow>
                            <ModelInfoLabel>Pituus</ModelInfoLabel>
                            <ModelInfoValue>{model.height}</ModelInfoValue>
                        </ModelInfoRow>}
                        {model.size && <ModelInfoRow>
                            <ModelInfoLabel>Koko</ModelInfoLabel>
                            <ModelInfoValue>{model.size}</ModelInfoValue>
                        </ModelInfoRow>}
                        {model.eyes && <ModelInfoRow>
                            <ModelInfoLabel>Silmät</ModelInfoLabel>
                            <ModelInfoValue>{model.eyes}</ModelInfoValue>
                        </ModelInfoRow>}
                        {model.hair && <ModelInfoRow>
                            <ModelInfoLabel>Hiukset</ModelInfoLabel>
                            <ModelInfoValue>{model.hair}</ModelInfoValue>
                        </ModelInfoRow>}
                        {model.foot && <ModelInfoRow>
                            <ModelInfoLabel>Kenkä</ModelInfoLabel>
                            <ModelInfoValue>{model.foot}</ModelInfoValue>
                        </ModelInfoRow>}
                    </ModelInfo>
                </ModelInfoWrapper>
                <Gallery images={galleryImages} margin={isWide ? 16 : 4} enableImageSelection={false} rowHeight={isWide ? 500 : 400} backdropClosesModal={true} />
                {/*images.map((image) => {
                    return (
                        <img src={image.fixed.src} />
                    )
                })*/}
            </ModelPageWrapper>
        </Layout>
    </>
  )
}

export const query = graphql`
  query ContentfulModel($slug: String, $category: String) {
    contentfulModel(slug: { eq: $slug }, category: { eq: $category }) {
        id
        name
        category
        height
        eyes
        size
        foot
        hair
        images {
            fixed(width: 380, quality: 100) {
                src
                width
                height
            }
        }
    }
  }
`

export default ModelPage
